
import Vue, { PropType } from "vue"
import { Camera } from "@evercam/shared/types/camera"
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

export default Vue.extend({
  components: {
    CameraStatusIcon,
  },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      default: () => ({} as Camera),
    },
    showLink: {
      type: Boolean,
      default: () => true,
    },
    showImage: {
      type: Boolean,
      default: () => true,
    },
    isCamera: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    marker360: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapStores(useAccountStore, useProjectStore),
    thumbnailUrl(): string {
      const url = `${this.camera?.thumbnailUrl}?authorization=${this.accountStore.token}`

      return this.isCamera
        ? this.$imgproxy.get360pResizedImageUrl(url)
        : this.marker360?.thumbnailLink
    },
    projectName(): string {
      return this.isCamera
        ? this.camera?.project?.name
        : this.projectStore.selectedProjectName
    },
  },
})
