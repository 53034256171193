
import CameraMapInfoWindow from "@/components/map/CameraMapInfoWindow"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import axios from "axios"
import { createCustomOverlay } from "@evercam/shared/utils"
import DownloadProgressSpinner from "@evercam/shared/components/DownloadProgressSpinner"
import CameraMarkerWithFov from "@evercam/shared/components/CameraMarkerWithFov"
import { useLayoutStore } from "@evercam/admin/stores/layout"
import { AnalyticsEventPageId } from "@evercam/shared/types"
/*global google*/

export default {
  name: "GoogleMap",
  meta: {
    pageId: AnalyticsEventPageId.projectMapView,
  },
  components: {
    CameraMapInfoWindow,
    DownloadProgressSpinner,
    CameraMarkerWithFov,
  },
  data() {
    return {
      mapViews: [],
      selectedMapView: null,
      historicalOverlay: null,
      center: {
        lat: 52.51195,
        lng: 6.089625,
      },
      map: null,
      selectedCamera: null,
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      currentMidx: null,
      zoom: 12,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      hoveredCamera: null,
      hoveredPosition: {},
      mapType: "terrain",
      isOverlayImgLoading: false,
      overLayImgProgress: 0,
    }
  },
  head() {
    return {
      title: `${
        this.projectStore.selectedProjectName || "Evercam"
      } | Project map`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useProjectStore, useLayoutStore),
    cameras() {
      return this.projectStore.selectedProjectCameras
    },
    drawFov() {
      return this.zoom >= 16
    },
    FOVCameras() {
      return this.cameras.filter((elt) => elt.isOnline && this.drawFov)
    },
    leftSideBarLeftPosition() {
      if (this.layoutStore.isPermanentLeftSidebar) {
        return "30px"
      } else {
        if (
          this.layoutStore.isLeftSidebarVisible &&
          this.layoutStore.isLeftSidebarMinimized
        ) {
          return "86px"
        } else if (
          this.layoutStore.isLeftSidebarVisible &&
          !this.layoutStore.isLeftSidebarMinimized
        ) {
          return "240px"
        } else {
          return "30px"
        }
      }
    },
    showCameraMapInfoTooltip() {
      return Boolean(this.selectedCamera)
    },
  },
  watch: {
    mapType(value) {
      this.map?.setMapTypeId(value)
    },
    showCameraMapInfoTooltip(visible) {
      this.$analytics.saveEvent(
        AnalyticsEvent.mapViewToggleCameraMapInfoTooltip,
        {
          visible,
        }
      )
    },
  },
  mounted() {
    this.$refs.gmap?.$mapPromise?.then((map) => {
      this.map = map

      const bounds = new google.maps.LatLngBounds()
      for (let m of this.cameras) {
        if (m.location) {
          bounds.extend(m.location)
        }
      }
      map.fitBounds(bounds)

      this.getMapViews()
    })
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
  methods: {
    toggleInfoWindow(camera) {
      this.infoWindowPos = camera.location
      this.selectedCamera = camera
      this.hoveredCamera = null
    },
    setHoveredCamera(camera) {
      if (this.selectedCamera) {
        return
      }

      this.hoveredCamera = camera
    },
    isCameraSelected(camera) {
      return (
        camera.id === this.hoveredCamera?.id ||
        camera.id === this.selectedCamera?.id
      )
    },
    zoomChanged(zoom) {
      this.zoom = zoom
    },
    cameraMouseMove(event) {
      if (this.hoveredCamera) {
        this.hoveredPosition = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        }
      }
    },
    async getMapViews() {
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/maps/${this.projectStore.selectedProjectExid}.json`
      )
      response = await response.json()
      this.mapViews = [{ name: "None" }]
      for (const key in response) {
        this.mapViews.push(response[key])
      }

      if (this.mapViews.length > 1) {
        this.selectedMapView = this.mapViews[0]
        await this.setMapView(this.selectedMapView)
        this.mapType = this.selectedMapView.mapStyle ?? "terrain"
      }
    },
    async fetchImage(url) {
      this.isOverlayImgLoading = true
      this.overLayImgProgress = 0
      const response = await axios.get(url, {
        responseType: "blob",
        onDownloadProgress: (ev) => {
          this.overLayImgProgress = Math.floor((ev.loaded / ev.total) * 100)
        },
      })
      const blob = response.data
      this.overLayImgProgress = -1
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      const image64 = await new Promise((resolve, reject) => {
        reader.onload = () => {
          const base64Image = reader.result
          if (base64Image) {
            resolve(base64Image)
          } else if (reader.error) {
            reject(new Error("Error While Loading the Map Asset"))
          }
        }
      })

      return image64
    },
    async setMapView(mapView) {
      this.historicalOverlay?.onRemove()
      if (!mapView.image) {
        return
      }
      this.isOverlayImgLoading = true
      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(mapView.south, mapView.west),
        new google.maps.LatLng(mapView.north, mapView.east)
      )
      if (!mapView.image64) {
        mapView.image64 = await this.fetchImage(mapView.image)
      }
      const overlay = createCustomOverlay(bounds, mapView.image64)
      overlay.setMap(this.map)
      this.mapType = mapView.mapStyle
      this.historicalOverlay = overlay
      const checkOverlayDraw = () => {
        if (overlay.div?.children.length > 0) {
          this.isOverlayImgLoading = false
        } else {
          setTimeout(checkOverlayDraw, 100)
        }
      }
      checkOverlayDraw()
    },
    onLinkClick() {
      this.$analytics.saveEvent(AnalyticsEvent.mapViewClickCameraLink)
    },
  },
}
